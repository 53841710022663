<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                国泰君安证券&非凸科技，探讨前沿技术赋能量化投资
              </p>
              <div class="fst-italic mb-2">2023年5月19日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >沙龙活动</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/39/01-会场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    面对新的时代背景和市场机遇，国泰君安主动对接大湾区建设国家战略，推动各业务线和属地分支机构优化升级大湾区行动计划，在金融科技赋能上，制定大湾区IT投入方案，推动大湾区量化交易客户敏捷型服务组织团队筹建，在大湾区试点推广新的策略托管模式，加大对大湾区量化交易、跨境交易系统的投入，加快自主算法交易在大湾区落地，开展算法统一评价建设，以客户价值为本，专业专注走科技赋能路线，在全心全力做好投资者需求匹配的同时，也积极推进各项新技术的储备，提供一站式、定制化、专业化、陪伴式、有温度的综合金融服务。
                  </p>
                  <p>
                    近日，国泰君安证券深圳分公司携手非凸科技开展的“科技而生·赋能量化”沙龙活动，特别邀请到上海靖奇投资、深圳衍盛资产出席本次活动分享行业洞察与专业见解，在座反响热烈，也引来了更多的思想碰撞和业态合作。
                  </p>
                  <p>
                    活动伊始，国泰君安数据中心祖晓伟介绍了国泰君安量化交易技术服务体系，整合机构客户前端、中端和后端需求，持续改进优化领先的量化交易技术和服务体验。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/39/02-国泰君安.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技也从量化行业概况、算法交易市场以及非凸产品几个角度分享了科技赋能金融的确定性与显著性。2022年底，国泰君安证券与非凸科技达成了总对总的合作，上线以来凭借着领先市场水平的绩效为量化管理人提供了优质高效的算法服务，加深了三方的合作链接，为业务拓展提供有力支持。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/39/03-非凸科技.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    最后，靖奇投资创始人唐靖人以轻松幽默的语言风格与在座的伙伴分享了量化产品的未来发展方向，并对年轻的量化新星报以希冀。衍盛资产合伙人李达从行业经验出发，分享了主观与量化对于算法交易的不同见解，以与非凸科技的合作为力，道出了管理人端对于三方算法的考量。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/39/04-靖奇投资.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/39/05-衍盛资产.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    国泰君安证券不仅积极拥抱科技合作伙伴，搭建了开放友好地行业交流平台，也是深度布局量化行业的先行者，注重量化人才的挖掘与培养，拟与南方科技大学联合举办了苏黎世数量金融风险夏令营，带领对量化感兴趣的学子们了解数量金融风险行业前沿趋势，学习全球数量金融风险先进技术。
                  </p>
                  <p>
                    最后感谢国泰君安证券对非凸科技合作伙伴的高度重视与信任，积极推进与非凸科技此次的行业共聚共议活动，共同营造行业金融科技创新氛围。
                  </p>
                  <p>
                    未来，非凸科技将持续以科技的力量，赋能量化行业的高质量发展。也将持续在提升营销展业效率、优化客户服务体验等方面提供助力。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News39",
};
</script>

<style></style>
